.select-property-mobile {
    display: none;
    

}
.select-property-desktop{
    /* display: none !important; */
    margin-left: 20px;
}
.site-name-heading{
    display: block !important;
    margin-left: 20px;
}

/* responsive code for small laptop devices */
@media only screen and (max-width: 1024px) {
    .room-list-img {
        padding: 20px 0px 0px 20px;
    }

    .room-list-icons {
        justify-content: center !important;
    }
}

/* responsive codes for tablet devices */
@media only screen and (max-width: 992px) {
    .room-list-icons {
        justify-content: center !important;
    }

    .room-list-img {
        border-bottom-left-radius: 0;
    }

    .mobile-calender {
        display: block !important;
    }

    .calender-room-container {
        display: none !important;
    }

    .room-list-img {
        padding: 20px 0px 0px 20px;
    }

    .responsive-table {
        width: 90vw !important;
    }
}

@media only screen and (max-width: 600px) {
    .room-types-page {
        padding: 20px 20px !important;
    }

    .room-type-heading {
        font-size: 26px;
    }

    .pages-top-btn-group {
        margin: 3px !important;
    }

    .pages-top-btn-group button {
        font-size: 12px;
    }

    .room-types-topbar {
        margin-bottom: 30px;
    }

    .room-list-img {
        width: 100%;
        border-top-right-radius: 5px;
    }

    .room-list-info {
        text-align: center;
    }

    /* .room-grid-list-item{
        margin-bottom: 20px;
    } */
    .room-list-icons {
        justify-content: center !important;
    }

    .room-list-icon-container {
        margin: 0;
    }

    .property-settings {
        padding: 5px 5px;
    }

    .edit-box {
        padding: 20px;
    }

    .edit-room {
        padding: 5px;
    }

    .name-this-room,
    .max-guests,
    .max-kids {
        width: 100% !important;
        margin-bottom: 20px !important;
    }

    .units {
        width: 100% !important;
    }

    .room-size {
        width: 95% !important;
    }

    .description-subtitle {
        padding: 0 !important;
    }

    .price-select {
        width: 100% !important;
    }

    .bed-row-one {
        display: grid !important;
        grid-template-columns: 3fr 2fr 1fr !important;
    }

    .description-box-container {
        width: 100% !important;
    }

    .calender-rooms {
        padding: 5px !important;
    }

    .settings-phone-number {
        width: 95% !important;
    }

    .settings-street-address {
        width: 100% !important;
    }

    .property-type-select,
    .time-zone-select {
        width: 100% !important;
    }

    .MuiDrawer-root.MuiDrawer-docked.css-11o2879-MuiDrawer-docked {
        display: none;
    }

    .full-width-input-field {
        width: 100% !important;
    }

    .edit-room-bottom {
        text-align: center !important;
    }

    .edit-room-bottom-btns {
        text-align: center !important;
    }

    .room-list-img {
        padding: 0;
    }

    .responsive-table {
        width: 80vw !important;
    }

    .responsive-table-two {
        width: 75vw !important;
    }

    .site-name-heading {
        margin: 0 !important;
    }

    .menu-button-icon {
        margin-right: 8px !important;
    }

    .user-name {
        font-size: 14px !important;
        margin-left: 10px !important;
        line-height: 1.2 !important;
    }

    .select-property-desktop{
        /* display: none !important; */
        margin-left: 1px;
    }
    .select-property-mobile {
        display: block;

    }
    .site-name-heading{
        display: none !important;
        margin-left: 1px;
    }
}