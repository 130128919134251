
.css-zxdg2z{
  padding: 0 !important;
}
.MuiDrawer-root.MuiDrawer-docked.css-1hsz34s-MuiDrawer-docked {
  position: fixed;
  z-index: 3;
}
.select-property-field fieldset{
  border-color: #fff;
}
