.selected{
    background-color: #9BCCF5;
}

.pink {
    background-color: #9BCCF5;
}

.calender-day {
    border-bottom: 1px solid #b6c1cd;
    background-color: #fff;
    padding: 2px 0px;
    position: relative;
}
.calender-day .calender-left-arrow{
    position: absolute;
    top: 35%;
    left: 40%;
    font-size: 20px;
}

.calender-day .calender-right-arrow{
    position: absolute;
    right: 40%;
    top: 35%;
}

.calender-day svg{
    font-size: 16px;
    cursor: pointer;
}
.calender-day svg:hover{
    color: #9BCCF5;

}
.calender-dates {
    background-color: #fff;
    padding: 0px 0px;
    border-bottom: 1px solid #b6c1cd;
    
}
.date-square{
    position: relative;
}

.calender-top-day {
    margin: 0px;
    font-size: 15px;
    color: #929292;
    font-weight: 500;
    text-align: center;
}

.calender-top-date {
    font-size: 13px;
    text-align: center;
    display: block;
    color: #929292;
}

.date-item {
    padding: 10px 0px;
    border-right: 1px solid #dce0e2;
}

.room-calender-details h4,
.room-calender-details p {
    margin: 0px;
}

.room-calender-details h4 {
    font-size: 17px;
    color: #2b5672;
    font-weight: 600;
}

.room-calender-details p {
    color: #929292;
    font-size: 14px;
}

.room-calender-details {
    padding: 4px 25px;
}

.date-item-lg {
    border-right: 1px solid #dce0e2;
    width: 60px;
    height: 60px;
    text-align: center;
    cursor: pointer;
}
.add-date-icon svg{
    margin: 0;
    display: inline-block;
    font-size: 25px;
    /* margin-top: 21%; */
    color: #9BCCF5;
    visibility: hidden;
    /* width: 100%;
    height: 100%; */
}
.date-item-lg:hover{
    border: 2px solid #9BCCF5;
}
.date-item-lg:hover .add-date-icon svg{
    visibility: visible;
}

/* popup styles */
.reservation-popup{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2b567207;
}
.reservation-popup-container{
    width: fit-content;
    height: fit-content;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 11px 0 rgb(0 0 0 / 21%);
    position: relative;
}
.reservation-btn{
    display: flex;
    align-items: center;
}
.reservation-btn svg {
    font-size: 15px;
    margin-right: 15px;
    color: #9BCCF5;
}
.reservation-btn a{
    text-decoration: none;
    color: #2b5672;
}
.hide-popup{
position: absolute;
top: 5px;
right: 5px;
}
.reserved-popup{
    position: absolute;
    top: 35%;
    left: 40%;
    width: fit-content;
}
.reserved-popup-container{
    width: 300px;
    height: 200px;
    background-color: #fff;
    box-shadow: 0 0 11px 0 rgb(0 0 0 / 21%);
    border-radius: 5px;
    padding: 15px;
}
.close-icon-reserved svg{
    font-size: 25px;
}
.calnendar-container {
	position: absolute;
	top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
}