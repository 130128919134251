.mui-section-loader {
    position: absolute;
    background: #0000ff52;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-Index: 10;

}

.half-rotate {
    animation: halfRotate 5s infinite;
}

@keyframes halfRotate {
    50% {
        transform: rotate(180deg);
    }
}

.full-rotate {
    animation: fullRotate 2s infinite linear;
}

@keyframes fullRotate {
    100% {
        transform: rotate(360deg);
    }
}