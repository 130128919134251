.edit-room, .property-settings{
    background-color: #F0F4F7;
    padding: 40px 60px;
}
.edit-box{
    padding: 50px 70px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 30px;
}

.edit-box:hover .edit-box-subtitle{
    visibility: visible;
}

.edit-box-title{
    font-size: 22px;
    font-weight: 400;
    color: #2b5672;
    margin: 0;
}
.edit-box-subtitle{
    color: #7a92a5;
    visibility: hidden;
    transition: all .3s;
}
.more-amenity-btn{
    background-color: transparent;
    border: none;
    font-size: 15px;
    color: #3899ec;
}
.room-photo{
    width: 100px !important;
    height: 100px !important;
    border-radius: 5px !important;
}
.add-room-photo{
    border: 1px dashed #3899ec;
    display: block;
    margin-left: 20px;
    line-height: 140px;
    position: relative;
    text-align: center;
}
.add-room-photo svg{
    color: #3899ec;
    font-size: 50px;
}
.dropzone {
    width: 100%;
    height: 100%;
}
.container.thumb-img-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.edit-room-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
}