.grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 2fr));
    grid-gap: 1rem;
}

.box__item{
    border: solid black 2px;
    height: 30px;
    width: 60px;
    cursor: pointer;
    padding: 10px 20px;
}

.p{
    color: red;
}

.section__padding{
    padding: 8rem 6rem;
}

.total{
    margin-top: 20px;
}

.blue{
    background: rgb(162, 184, 224) !important;;
}

.pink{
  background:#FF69B4 
}