.login-page{
    background-color: #a7ac99;
    /* background-image: url(/public/images/hotel-login-bg.jpg), linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)); */
    background-position: center;
    background-blend-mode: overlay;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0px;
    height: 100vh;
}

.login-container{
    max-width: 1560px;
    margin: auto;
    display: flex;
    justify-content: center;
}
.login-form-container{
    padding: 100px 40px;
    width: 35%;
}
.login-input{
    width: 100%;
    background-color: #fff;
    border: 1px solid #fff;
}
.login-input input{
    font-weight: 600;
}
.login-checkbox{
    color: #fff;
    margin-bottom: 15px;
    font-weight: 600;
}
.login-form-checkbox .css-i4bv87-MuiSvgIcon-root{
    color: #fff !important;
}
.social-login{
    /* background-color: #000; */
    padding: 12px;
    border-radius: 50%;
    cursor: pointer;
}
.social-login-facebook{
    background-color: #3A579A;
}
.social-login-google{
    background-color: #DC4A35;
}
.login-input svg{
    color: #211f1f89 !important
}

.swal2-popup.swal2-toast{
    /* z-index: 2000 !important; */
    position: relative;
    top: 80px !important;
    /* background-color: #DC4A35; */
}
/* header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.mui-fixed.css-19z1ozs-MuiPaper-root-MuiAppBar-root{
    z-index: 1300 !important;
} */


/* responsive code */
@media only screen and (max-width: 992px) {
    .login-form-container{
        width: 60%;
    }
    .login-page{
        height: fit-content;
    }
}
@media only screen and (max-width: 576px){
    .login-form-container{
        width: 95%;
    }
    .login-page{
        height: fit-content;
    }
}