

@media only screen and (max-width: 600px) {
    .desktop-grid{
        display: none!important;
    }
    .mobile-grid{
        display: block!important;
    }

    .reservation-list-desktop{
        display: none !important;
    }

    .reservation-list-mobile{
        display: flex !important;
    }

}
@media only screen and (max-width: 768px) {
    .guest_details_container{
        margin-top: 50px !important;
    }
    .guest_info_field {
        /* margin: 0!important; */
    }
}
@media only screen and (min-width: 601px) {
    .mobile-grid{
        display: none;
        margin-top: 20px;
    }
    .reservation-list-mobile{
        display: none !important;
    }
}