.room-types-page{
    background-color: #F0F4F7;
    padding: 40px 60px;
}
.room-type-heading{
    color: #2b5672;
    font-weight: 400;
    font-size: 34px;
}
.room-typy-topbar-btn:hover{
    border: none!important;
}
.create-new-btn:hover{
    background-color: #6eb7fd !important;
}
.preview-btn:hover{
    background-color: #4eb7f5!important;
    color: #fff;
}
.room-grid-list-item{
    background-color: #fff;
    margin-bottom: 40px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px #eae4e4;
}
.room-list-img{
    height: 100%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}
.room-list-info{
    display: flex;
    flex-direction: column;
}
.room-list-info a{
    color: #2b5672;
    text-decoration: none;
    font-size: 20px;
}
.room-list-info a:hover{
    color: #4fa5ee;
}
.room-list-info p{
    margin: 0 !important;
}
.room-list-content{
    display: flex;
    align-items: center;
}
.room-list-icon{
    padding: 8px 8px;
    line-height: 15px;
    margin: 2px;
    display: inline-block;
    border-radius: 50%;
    color: #3899ec;
    transition: all .3s;
    cursor: pointer;
}
.room-list-icon:hover{
    
    background-color: #3898ec2c;
}
.room-list-icon-container{
    margin-right: 20px;
}
.room-list-drag-icon{
    padding: 8px 8px;
    line-height: 15px;
    margin: 2px;
    margin-left: 10px;
    display: inline-block;
    border-radius: 50%;
    color: rgb(219, 213, 213);
    transition: all .3s;
    cursor:grabbing;
}